<template>
  <div>
    <v-btn
      :to="{ name: 'routemapsCreateUpdate' }"
      class="btn btn-success btn-elevate kt-login__btn-primary text-white"
      >{{ $t("MENU.ADD") }} {{ $t("BUTTON.NEW") }}
      {{ $t("COMPONENTS.ROUTEMAP") }}</v-btn
    >

    <v-container>
      <v-row no-gutters>
        <v-col cols="4">
          <v-text-field
            v-model="headers.search"
            prepend-inner-icon="search"
            clearable
            :label="$t('A.SEARCH')"
          ></v-text-field>
        </v-col>
        <v-col cols="4"></v-col>
        <v-col class="text-right" cols="2">
          <v-select
            v-model="headers.routeMapStatus"
            :items="routeMapStatus"
            item-text="name"
            item-value="id"
            label="Статуси"
            chips
            attach
          ></v-select>
        </v-col>
        <v-col class="text-right" cols="2">
          <v-select
            v-model="headers.rows"
            :items="tableOptions.rowsPerPage"
            :label="$t('A.PER_PAGE')"
            chips
            attach
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="mb-6 t-header">
        <template v-for="column of headers.columns">
          <v-col :key="column.name" :cols="column.cols" v-if="!column.hide">
            <span @click="changeOrder(column.name)">
              {{ $t("LABELS." + column.name) }}
              <span v-if="column.name == headers.order">{{
                orderIcon
              }}</span></span
            >
          </v-col>
        </template>

        <v-col cols="2"> Брой </v-col>
        <v-col> Операции </v-col>
      </v-row>

      <div v-show="!isLoadingRoutemaps">
        <v-row class="mb-6 t-row" v-for="item of items" :key="item.id">
          <template v-for="col of headers.columns">
            <v-col
              class="t-col"
              v-if="!col.hide"
              :key="col.name"
              :cols="col.cols"
            >
              {{ item[col.name] }}
            </v-col>
          </template>
          <v-col class="t-col" :cols="2">
            {{ item.createdQuantity }}/{{ item.quantity }}
          </v-col>
          <v-col
            class="t-col d-flex justify-content-start operation-container flex-wrap"
          >
            <template v-if="item.statusId != 8">
              <v-tooltip v-if="item.statusId !== 9" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 mt-2"
                    fab
                    dark
                    x-small
                    color="#3dbdad"
                    v-bind="attrs"
                    v-on="on"
                    @click="openSignDialog(item.id)"
                    v-if="
                      (user.role == 'Admin' || user.role == 'Qualified') &&
                        item.signedByQualified != 1
                    "
                  >
                    <v-icon> fas fa-file-signature </v-icon>
                  </v-btn>
                </template>
                <span>Подпиши</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 mt-2"
                    fab
                    dark
                    x-small
                    color="#3dbdad"
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadPDF(item.id)"
                  >
                    <v-icon> fas fa-file-alt </v-icon>
                  </v-btn>
                </template>
                <span>Изтегли пълния документ</span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="
                  item.signedByQualified != 1 ||
                    ['Admin', 'Qualified'].includes(user.role)
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 mt-2"
                    fab
                    dark
                    x-small
                    color="#3dbdad"
                    v-bind="attrs"
                    v-on="on"
                    @click="openUploadDialog(item.id)"
                  >
                    <v-icon> fas fa-file-upload </v-icon>
                  </v-btn>
                </template>
                <span>Файлове</span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="
                  user.role == 'Admin' ||
                    user.role == 'Qualified' ||
                    user.role == 'Manager'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 mt-2"
                    fab
                    dark
                    x-small
                    color="#3dbdad"
                    v-bind="attrs"
                    v-on="on"
                    @click="regenerateDocument(item.id)"
                  >
                    <v-icon>fas fa-sync</v-icon>
                  </v-btn>
                </template>
                <span>Регенерирай Документ</span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="
                  (user.role == 'Qualified' || user.role == 'Admin') &&
                    item.signedByQualified &&
                    item.statusId != 9
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 mt-2"
                    fab
                    dark
                    x-small
                    color="#3dbdad"
                    v-bind="attrs"
                    v-on="on"
                    @click="openEditAuthorizationHolderDialog(item)"
                  >
                    <v-icon>fas fa-user-edit</v-icon>
                  </v-btn>
                </template>
                <span>Редактирай притежател на разрешение за употреба</span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="
                  (user.role == 'Qualified' || user.role == 'Admin') &&
                    item.signedByQualified &&
                    item.statusId == 9
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 mt-2"
                    fab
                    dark
                    x-small
                    color="#3dbdad"
                    v-bind="attrs"
                    v-on="on"
                    @click="openRouteMapBlockingDialog(item)"
                  >
                    <v-icon>fas fa-user-edit</v-icon>
                  </v-btn>
                </template>
                <span>Редактирай информацията за блокиране</span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="
                  (user.role == 'Qualified' || user.role == 'Admin') &&
                    item.statusId == 5
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 mt-2"
                    fab
                    dark
                    x-small
                    color="#3dbdad"
                    v-bind="attrs"
                    v-on="on"
                    @click="openRouteMapBlockingDialog(item)"
                  >
                    <v-icon>fas fa-ban</v-icon>
                  </v-btn>
                </template>
                <span>Блокирай</span>
              </v-tooltip>
              <EditButton
                v-if="
                  user.role == 'Admin' ||
                    user.role == 'Qualified' ||
                    (!item.signedByQualified && user.role == 'Manager')
                "
                :id="item.id"
                action-name="routemapsCreateUpdate"
              />
              <PreviewButton
                v-if="
                  user.role == 'Admin' ||
                    user.role == 'Qualified' ||
                    (!item.signedByQualified && user.role == 'Manager')
                "
                action-name="routemapsActions"
                :id="item.id"
              />
            </template>
            <LogLink :id="item.id" type="route-map" class-name="row" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            {{ totalItems }} {{ $t("A.RESULTS") }}
          </v-col>
        </v-row>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingRoutemaps"
      ></v-progress-linear>

      <Paginator
        :totalPages="totalPages"
        :page="headers.page"
        :maxVisibleButtons="tableOptions.maxVisibleButtons"
        @change-page="changePage"
      />
      <v-dialog v-model="sign_dialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Притежател на разрешение за употреба
          </v-card-title>

          <v-card-text>
            <vue-form-generator
              ref="form"
              :model="signDialogData"
              :schema="signDialogSchema"
            ></vue-form-generator>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="sign()"> Подпиши </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="editAuthorizationHolderDialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Притежател на разрешение за употреба
          </v-card-title>
          <div
            v-if="
              typeof error_messages === 'object' &&
                Object.keys(error_messages).length > 0
            "
            class="alert alert-danger"
          >
            <ul>
              <template v-for="(error, k) in error_messages">
                <span v-bind:key="k">
                  <li v-for="(message, i) in error" v-bind:key="i">
                    {{ message }}
                  </li>
                </span>
              </template>
            </ul>
          </div>
          <v-card-text>
            <vue-form-generator
              ref="form"
              :model="editAuthorizationHolderDialogData"
              :schema="editAuthorizationHolderDialogSchema"
            ></vue-form-generator>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="editAuthorizationHolder()"> Редактирай </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="routeMapBlockingDialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Блокиране на маршрутна карта
          </v-card-title>
          <div
            v-if="
              typeof error_messages === 'object' &&
                Object.keys(error_messages).length > 0
            "
            class="alert alert-danger"
          >
            <ul>
              <template v-for="(error, k) in error_messages">
                <span v-bind:key="k">
                  <li v-for="(message, i) in error" v-bind:key="i">
                    {{ message }}
                  </li>
                </span>
              </template>
            </ul>
          </div>
          <v-card-text>
            <vue-form-generator
              ref="form"
              :model="routeMapBlockingDialogData"
              :schema="routeMapBlockingDialogSchema"
            ></vue-form-generator>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="routeMapBlocking()">
              {{ routeMapBlockingDialogData.edit ? "Редактирай" : "Блокирай" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="upload_dialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2"> Файлове </v-card-title>

          <v-card-text>
            <ul>
              <template v-for="file in routemap_files">
                <h5
                  class="border-bottom"
                  v-on:click="getFile(file.id)"
                  v-bind:key="file.id"
                >
                  {{ file.filename }}.{{ file.extension }}
                  <span class="btn btn-primary" v-on:click="getFile(file.id)"
                    >Изтегли</span
                  >&nbsp;
                  <span
                    class="btn btn-danger"
                    v-on:click.stop="deleteFile(file.id)"
                    v-if="
                      user.role == 'Admin' ||
                        user.role == 'Qualified' ||
                        (file.isGenerated == 0 &&
                          upload_dialog_routemap &&
                          upload_dialog_routemap.signedByQualified != 1)
                    "
                    >Изтрий</span
                  >
                </h5>
              </template>
            </ul>
          </v-card-text>
          <template
            v-if="
              user.role == 'Admin' ||
                user.role == 'Qualified' ||
                (upload_dialog_routemap &&
                  upload_dialog_routemap.signedByQualified != 1)
            "
          >
            <v-card-title class="headline grey lighten-2">
              Качи файл
            </v-card-title>

            <v-card-text>
              <div
                v-if="
                  typeof error_messages === 'object' &&
                    Object.keys(error_messages).length > 0
                "
                class="alert alert-danger"
              >
                <ul>
                  <template v-for="(error, k) in error_messages">
                    <span v-bind:key="k">
                      <li v-for="(message, i) in error" v-bind:key="i">
                        {{ message }}
                      </li>
                    </span>
                  </template>
                </ul>
              </div>

              <div class="col-12">
                <h5>Заглавие на документа:</h5>
                <input type="text" class="form-control" v-model="filename" />
              </div>
              <div class="col-12">
                <input
                  ref="file"
                  id="file"
                  type="file"
                  name="file"
                  v-on:change="handleFileUpload()"
                />
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="uploadFile()"> Качи </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  GET_ROUTEMAPS,
  DELETE_ROUTEMAP,
  SIGN_ROUTEMAP,
  EDIT_AUTHORIZATION_HOLDER_ROUTEMAP,
  REGENERATE_ROUTEMAP_FILES,
  BLOCK_ROUTEMAP
} from "@/store/routemaps.module";

import {
  MERGE_ROUTEMAP_FILE,
  GET_FILE,
  GET_FILES_BY_TYPE
} from "@/store/files.module";

import { APP_URL } from "@/common/config";

import { mapGetters } from "vuex";

import { API_URL } from "@/common/config";
import { DELETE_FILE } from "@/store/files.module";

import axios from "axios";

import moment from "moment";

import Paginator from "@/components/Paginator";

import ListMixin from "@/common/mixins/List";
import VueFormGenerator from "vue-form-generator";
import EditButton from "@/components/ActionButtons/EditButton";
import PreviewButton from "@/components/ActionButtons/PreviewButton";
import LogLink from "@/components/Log/LogLink";
import { GET_DATA } from "@/store/formselect.module";

export default {
  name: "ListRoutemaps",

  components: {
    EditButton,
    PreviewButton,
    LogLink,
    Paginator,
    "vue-form-generator": VueFormGenerator.component
  },
  mixins: [ListMixin],
  data() {
    return {
      routeMapStatus: [
        {
          id: "",
          name: "Всички"
        }
      ],
      action: "list_done",
      filename: "",
      error_messages: [],
      items: [],
      file: "",
      upload_dialog: false,
      upload_dialog_routemap: null,
      upload_dialog_id: 0,
      sign_dialog: false,
      editAuthorizationHolderDialog: false,
      sign_dialog_id: 0,
      editAuthorizationHolderDialogId: 0,
      routeMapBlockingDialog: false,
      routeMapBlockingDialogData: {
        edit: false,
        reason: "",
        routemapAuthorizationHolder: ""
      },
      routeMapBlockingDialogId: null,
      routeMapBlockingDialogSchema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Причина",
            model: "reason",
            required: true
          },
          {
            type: "input",
            inputType: "text",
            label: "Притежател на разрешение за употреба",
            model: "routemapAuthorizationHolder",
            required: true
          }
        ]
      },
      sign_dialog_text: "",
      signDialogSchema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Притежател на разрешение за употреба",
            model: "routemapAuthorizationHolder",
            required: true
          }
        ]
      },
      editAuthorizationHolderDialogSchema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Притежател на разрешение за употреба",
            model: "routemapAuthorizationHolder",
            required: true
          }
        ]
      },
      totalItems: 0,
      routemap_files: [],
      signDialogData: {
        routemapAuthorizationHolder: null
      },
      editAuthorizationHolderDialogData: {
        routemapAuthorizationHolder: null
      },
      headers: {
        columns: [
          {
            name: "id",
            cols: "1",
            hide: false
          },
          {
            name: "product.name",
            search: "",
            hide: false,
            cols: "2"
          },
          {
            name: "batch",
            cols: "1",
            hide: false
          },
          {
            name: "productManager.name",
            search: "",
            hide: false,
            cols: "1"
          },
          {
            name: "productId",
            search: "",
            hide: true
          },
          {
            name: "signed_by_qualified",
            search: "",
            hide: true
          },
          {
            name: "certificate.ma_holder",
            search: "",
            hide: true
          },
          {
            name: "blockedInfo.reason",
            search: "",
            hide: true
          },
          {
            name: "blockedInfo.authorization_holder",
            search: "",
            hide: true
          },
          {
            name: "finishDate",
            search: "",
            cols: "1",
            hide: false
          },

          {
            name: "statusId",
            search: "[5,6,7,8,9]",
            hide: true
          },
          {
            name: "status.name",
            cols: "2"
          },
          {
            name: "quantity",
            search: "",
            hide: true
          },
          {
            name: "created_quantity",
            search: "",
            hide: true
          }
        ],
        search: "",
        routeMapStatus: "",
        rows: 10,
        page: 1,
        order: "finishDate",
        orderType: "desc"
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingRoutemaps"]),
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    },
    user: function() {
      return JSON.parse(window.localStorage.getItem("userData"));
    }
  },
  watch: {
    "headers.search": function(new_value) {
      let vm = this;

      vm.headers.page = 1;
      if (new_value == null || new_value.length >= 3) {
        vm.fetchData();
      }
    },
    "headers.routeMapStatus": function() {
      let vm = this;

      vm.headers.page = 1;
      vm.fetchData();
    },
    "headers.rows": function(new_value) {
      let vm = this;
      new_value = parseInt(new_value);
      window.localStorage.setItem(vm.action + "_rows", new_value);

      vm.headers.page = 1;
      vm.fetchData();
    },
    "headers.page": function() {
      let vm = this;
      vm.fetchData();
    }
  },
  mounted() {
    let vm = this;

    vm.$store.dispatch(SET_BREADCRUMB, [{ title: vm.$i18n.t("MENU.TABLE") }]);

    vm.fetchStatuses();
    vm.fetchData();
  },
  methods: {
    fetchStatuses: function() {
      let vm = this;

      let payload = {
        routeMapFinishedStatus: {
          fields: ["id", "name"]
        }
      };
      this.error_messages = [];
      this.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.routeMapStatus = vm.routeMapStatus.concat(
              _.orderBy(data.data.routeMapFinishedStatus, "name")
            );
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    regenerateDocument(routeMapId) {
      let vm = this;
      vm.$store
        .dispatch(REGENERATE_ROUTEMAP_FILES, routeMapId)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
          }
        });
    },

    deleteFile: function(fileId) {
      let vm = this;
      vm.$store
        .dispatch(DELETE_FILE, fileId)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.upload_dialog = false;
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
          }
        });
    },
    formatDate: function(rr) {
      return moment(rr).format("HH:mm DD.MM.YYYY");
    },
    openSignDialog(id) {
      let vm = this;
      vm.sign_dialog = true;
      vm.sign_dialog_id = id;
    },
    openEditAuthorizationHolderDialog(item) {
      let vm = this;
      vm.editAuthorizationHolderDialog = true;
      vm.editAuthorizationHolderDialogData.routemapAuthorizationHolder =
        item["certificate.maHolder"];
      vm.editAuthorizationHolderDialogId = item.id;
    },
    openRouteMapBlockingDialog(item) {
      let vm = this;

      vm.routeMapBlockingDialog = true;
      vm.routeMapBlockingDialogData.edit = item["blockedInfo.reason"]
        ? true
        : false;
      vm.routeMapBlockingDialogData.reason = item["blockedInfo.reason"];
      vm.routeMapBlockingDialogData.routemapAuthorizationHolder =
        item["blockedInfo.authorizationHolder"];
      vm.routeMapBlockingDialogData.vendorId = item["blockedInfo.vendorId"];
      vm.routeMapBlockingDialogId = item.id;
    },
    routeMapBlocking() {
      let vm = this;

      this.error_messages = [];

      let payload = {
        id: vm.routeMapBlockingDialogId,
        ...vm.routeMapBlockingDialogData
      };

      vm.$store
        .dispatch(BLOCK_ROUTEMAP, payload)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.routeMapBlockingDialog = false;

          vm.fetchData();
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "routemapsListDone" });
          }
        });
    },
    uploadFile() {
      let vm = this;
      let formData = new FormData();

      formData.append("file", this.file);
      formData.append("type", "route-map");
      formData.append("filename", vm.filename);
      formData.append("id", vm.upload_dialog_id);

      axios
        .post(API_URL + "files/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(({ data }) => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.getRoutemapFiles(vm.upload_dialog_id);

          vm.file = "";
          vm.filename = "";
          vm.upload_dialog = false;
          vm.upload_dialog_id = 0;
          vm.upload_dialog_routemap = null;
          vm.$refs.file.value = null;
          vm.error_messages = [];
        })
        .catch(({ response }) => {
          vm.error_messages = response.data.errors;
        });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    openUploadDialog: function(id) {
      let vm = this;
      vm.getRoutemapFiles(id);
      vm.upload_dialog = true;
      vm.upload_dialog_id = id;
      vm.upload_dialog_routemap = _.find(vm.items, item => item.id == id);
    },
    sign: function() {
      let vm = this;
      var action = SIGN_ROUTEMAP;

      this.error_messages = [];

      let payload = {
        id: vm.sign_dialog_id,
        authorization_holder: vm.signDialogData.routemapAuthorizationHolder
      };

      vm.$store
        .dispatch(action, payload)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.sign_dialog = false;

          vm.fetchData();
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "routemapsListDone" });
          }
        });
    },
    editAuthorizationHolder: function() {
      let vm = this;
      var action = EDIT_AUTHORIZATION_HOLDER_ROUTEMAP;

      this.error_messages = [];

      let payload = {
        id: vm.editAuthorizationHolderDialogId,
        authorization_holder:
          vm.editAuthorizationHolderDialogData.routemapAuthorizationHolder
      };

      vm.$store
        .dispatch(action, payload)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.editAuthorizationHolderDialog = false;

          vm.fetchData();
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "routemapsListDone" });
          }
        });
    },
    downloadCertificate: function(id) {
      let vm = this;

      vm.$store
        .dispatch(GET_FILES_BY_TYPE, {
          file_type: "product-certificate",
          resource_id: id
        })
        .then(data => {
          vm.getFile(data.data[0].id);
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "routemapsListDone" });
          }
        });
    },
    getRoutemapFiles: function(id) {
      let vm = this;
      vm.routemap_files = [];
      vm.$store
        .dispatch(GET_FILES_BY_TYPE, {
          file_type: "route-map",
          resource_id: id
        })
        .then(data => {
          vm.routemap_files = data.data;
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "routemapsListDone" });
          }
        });
    },
    downloadPDF: function(id) {
      let vm = this;

      vm.$store
        .dispatch(MERGE_ROUTEMAP_FILE, id)
        .then(data => {
          vm.streamFile(data.data.code);
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "routemapsListDone" });
          }
        });
    },
    getFiles: function(id) {
      let vm = this;

      vm.$store
        .dispatch(GET_FILE, id)
        .then(data => {
          vm.files = data;
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "routemapsListDone" });
          }
        });
    },
    getFile: function(id) {
      let vm = this;

      vm.$store
        .dispatch(GET_FILE, id)
        .then(data => {
          vm.streamFile(data.data.code);
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "routemapsListDone" });
          }
        });
    },
    streamFile: function(code) {
      window.open(APP_URL + "file/" + code);
    },
    getOptions: function() {
      let vm = this;

      vm.headers.order =
        window.localStorage.getItem(vm.action + "_order") || "id";
      vm.headers.orderType =
        window.localStorage.getItem(vm.action + "_orderType") || "asc";
      vm.headers.rows = parseInt(
        window.localStorage.getItem(vm.action + "_rows") || 10
      );
    },
    changeOrder: function(key) {
      let vm = this;

      if (vm.headers.order == key) {
        let oType = vm.headers.orderType == "asc" ? "desc" : "asc";

        vm.headers.orderType = oType;
      } else {
        vm.headers.order = key;
      }

      vm.headers.page = 1;
      window.localStorage.setItem(vm.action + "_order", vm.headers.order);
      window.localStorage.setItem(
        vm.action + "_orderType",
        vm.headers.orderType
      );

      vm.fetchData();
    },
    fetchData: function() {
      let vm = this;

      vm.getOptions();

      vm.$store
        .dispatch(GET_ROUTEMAPS, { payload: vm.headers })
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data.data;
            vm.totalItems = data.data.recordsFiltered;
            vm.totalPages = data.data.totalPages;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "routemapsListDone" });
          }
        });
    },
    deleteData: function(item) {
      let vm = this;

      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "Tова дейтсвие не може да бъде отменено!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.execDelete(item);
        },
        onCancel() {}
      });
    },
    execDelete: function(item) {
      let vm = this;

      vm.$store
        .dispatch(DELETE_ROUTEMAP, item.id)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchData();
          // vm.$nextTick(function() {
          //   vm.items = data.data.data;
          //   vm.totalItems = data.data.recordsTotal;
          //   vm.totalPages = data.data.totalPages;
          // });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "routemapsListDone" });
          }
        });
    }
  }
};
</script>
